import React from 'react';
import { SpringHelperConfig } from 'react-motion';
import { Middleware } from './interfaces';

interface SpringConfig extends SpringHelperConfig {
    // mass?: number;
    // tension?: number;
    // friction?: number;
    // damp?: boolean;
    // velocity?: number;
    // easing?: (t: number) => number;
    // progress?: number;
    // duration?: number;
    // decay?: number | true;
    // frequency?: number;
    // round?: boolean;
    // bounce?: number;
    // restVelocity?: number;
}

interface Props {
    factorX?: number;
    factorY?: number;
    springConfig?: SpringConfig;
    children?: JSX.Element | JSX.Element[];
    updateStyles?: Middleware | React.CSSProperties;
}

const MouseParallaxChild = ({ children }: Props) => (<> {children} </>);

export default MouseParallaxChild;